import React from "react";

export const Backend = () => {
	return (
		<div className="skills__content">
			<h3 className="skills__title">Backend</h3>

			<div className="skills__box">
				<div className="skills__group">
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">NodeJS</h3>
							<span className="skills__level">Basic</span>
						</div>
					</div>
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">Typescript</h3>
							<span className="skills__level">Basic</span>
						</div>
					</div>
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">Postgresql</h3>
							<span className="skills__level">Intermediate</span>
						</div>
					</div>
				</div>

				<div className="skills__group">
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">.NET 8</h3>
							<span className="skills__level">Basic</span>
						</div>
					</div>
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">Oracle</h3>
							<span className="skills__level">Basic</span>
						</div>
					</div>
					<div className="skills__data">
						<i className="bx bx-badge-check"></i>
						<div>
							<h3 className="skills__name">Git</h3>
							<span className="skills__level">Intermediate</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
