import React, { useState } from "react";
import "./qualifications.css";

export const Qualifications = () => {
	const [tabActive, setTabActive] = useState(1);

	const handleTabChange = (tab) => {
		setTabActive(tab);
	};

	return (
		<section className="qualification section" id="qualifications">
			<h2 className="section__title">Qualifications</h2>
			<span className="section__subtitle">My personal journey</span>

			<div className="qualification__container container">
				<div className="qualification__tabs">
					<div
						className={`qualification__button button--flex ${
							tabActive === 1 ? "qualification__active" : ""
						}`}
						onClick={() => handleTabChange(1)}
					>
						<i className="uil uil-graduation-cap qualification__icon"></i>{" "}
						Education
					</div>

					<div
						className={`qualification__button button--flex ${
							tabActive === 2 ? "qualification__active" : ""
						}`}
						onClick={() => handleTabChange(2)}
					>
						<i className="uil uil-briefcase-alt qualification__icon"></i>{" "}
						Experience
					</div>
				</div>

				<div className="qualification__sections">
					<div
						className={`qualification__content ${
							tabActive === 1 ? "qualification__content-active" : ""
						}`}
					>
						<div className="qualification__data">
							<div>
								<h3 className="qualification__title">Computer Engeneering</h3>
								<span className="qualification__subtitle">
									Santa Cecília University
								</span>
								<div className="qualification__calendar">
									<i className="uil uil-calendar-alt"></i> 2020 - 2024
								</div>
							</div>

							<div>
								<span className="qualification__rounder"></span>
								<span className="qualification__line"></span>
							</div>
						</div>

						<div className="qualification__data">
							<div></div>

							<div>
								<span className="qualification__rounder"></span>
								<span className="qualification__line"></span>
							</div>

							<div>
								<h3 className="qualification__title">Graduation</h3>
								<span className="qualification__subtitle">
									Liceu Santista College
								</span>
								<div className="qualification__calendar">
									<i className="uil uil-calendar-alt"></i> 2017 - 2019
								</div>
							</div>
						</div>
					</div>

					<div
						className={`qualification__content ${
							tabActive === 2 ? "qualification__content-active" : ""
						}`}
					>
						<div className="qualification__data">
							<div></div>

							<div>
								<span className="qualification__rounder"></span>
								<span className="qualification__line"></span>
							</div>

							<div>
								<h3 className="qualification__title">Developer</h3>
								<span className="qualification__subtitle">
									Autoglass - Brasil
								</span>
								<div className="qualification__calendar">
									<i className="uil uil-calendar-alt"></i> Dec 2022 - Present
								</div>
							</div>
						</div>

						<div className="qualification__data">
							<div>
								<h3 className="qualification__title">FullStack Developer</h3>
								<span className="qualification__subtitle">
									GBM Tech & Control
								</span>
								<div className="qualification__calendar">
									<i className="uil uil-calendar-alt"></i> Jan 2022 - Dec 2022
								</div>
							</div>

							<div>
								<span className="qualification__rounder"></span>
								<span className="qualification__line"></span>
							</div>
						</div>

						<div className="qualification__data">
							<div></div>

							<div>
								<span className="qualification__rounder"></span>
								<span className="qualification__line"></span>
							</div>

							<div>
								<h3 className="qualification__title">Intern Developer</h3>
								<span className="qualification__subtitle">
									GBM Tech & Control
								</span>
								<div className="qualification__calendar">
									<i className="uil uil-calendar-alt"></i> Sep 2021 - Dec 2021
								</div>
							</div>
						</div>

						<div className="qualification__data">
							<div>
								<h3 className="qualification__title">Analyst and Developer</h3>
								<span className="qualification__subtitle">MultipontoMKT</span>
								<div className="qualification__calendar">
									<i className="uil uil-calendar-alt"></i> Jan 2019 - Sep 2021
								</div>
							</div>

							<div>
								<span className="qualification__rounder"></span>
								<span className="qualification__line"></span>
							</div>
						</div>

						<div className="qualification__data">
							<div></div>

							<div>
								<span className="qualification__rounder"></span>
								<span className="qualification__line"></span>
							</div>

							<div>
								<h3 className="qualification__title">Marketing Assistant</h3>
								<span className="qualification__subtitle">MultipontoMKT</span>
								<div className="qualification__calendar">
									<i className="uil uil-calendar-alt"></i> Jan 2016 - Dec 2018
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
