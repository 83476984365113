import React from "react";
import "./about.css";
import CV from "../../assets/resume_emf2025.pdf";
import FilesIcon from "../../assets/files.svg";
import { Info } from "./Info";

export const About = () => {
	return (
		<section className="about section" id="about">
			<h2 className="section__title">About Me</h2>
			<span className="section__subtitle">My introduction</span>

			<div className="about__container container grid">
				<img
					src="https://github.com/enricomadeu.png"
					alt=""
					className="about__img"
				/>

				<div className="about__data">
					<Info />

					<p className="about__description">
						I've worked developing customized solutions for clients encompassing
						websites, e-commerces, digital marketing and social media platforms.
						Today, passionate about technology and programming, I work as a Full
						Stack developer. Ingenious professional, well organized,
						hardworking, determined, focused on excellence and easy to get along
						with.
					</p>

					<a download="" href={CV} className="button button--flex">
						Download CV&nbsp; <img src={FilesIcon} alt="File icon" />
					</a>
				</div>
			</div>
		</section>
	);
};
